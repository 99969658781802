import React from "react";
// import gftDarkLogo from "../../images/logos/gftlogodark.png";
// import gftDarkLight from "../../images/logos/gftlogolight.png";
import gftDarkLogo from "../../assets/images/logos/gftlogolight.png";
import gftDarkLight from "../../assets/images/logos/gftlogolight.png";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useSelector } from "react-redux";
import Terms from "../Terms";
import InclusionsAndExclusions from "../../components/InclusionsAndExclusions";

const TimeLineOrcha = () => {
  const darkModeState = useSelector((state) => state.darkmode);

  return (
    <>
      <div className="bg-primary w-full bg-[#334155] xl:mt-32 bg-cover bg-center bg-[url(https://images.unsplash.com/photo-1578645546130-a8e4c5becb17?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80)] bg-fixed">
        <VerticalTimeline lineColor="white">
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgba(0, 0, 0, 0.3)",
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              color: "white",
            }}
            contentArrowStyle={{
              borderRight: "7px solid var(--static-secondary)",
            }}
            date="Day 1 - Friday, 25th August"
            iconStyle={{
              background: "var(--secondary)",
              color: "white",
              overflow: "hidden",
            }}
            icon={
              <div className="h-full w-full p-2 bg-[#334155]">
                <img
                  src={darkModeState === "light" ? gftDarkLight : gftDarkLogo}
                  className="h-full w-full object-contain object-center"
                  alt="logo"
                />
              </div>
            }
          >
            <h3 className="vertical-timeline-element-title font-semibold underline underline-offset-4 italic">
              Indore to Orchha
            </h3>
            <ul>
              <li>
                &#8226; Gather at a specified location in Indore on Friday
                night.
              </li>
              <li>
                &#8226; Depart for Orchha, a historical town, on an overnight
                journey.
              </li>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgba(0, 0, 0, 0.3)",
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              color: "white",
            }}
            contentArrowStyle={{
              borderRight: "7px solid var(--static-secondary)",
            }}
            date="Day 2 - Saturday, 26th August"
            iconStyle={{
              background: "var(--secondary)",
              color: "white",
              overflow: "hidden",
            }}
            icon={
              <div className="h-full w-full p-2 bg-[#334155]">
                <img
                  src={darkModeState === "light" ? gftDarkLight : gftDarkLogo}
                  className="h-full w-full object-contain object-center"
                  alt="logo"
                />
              </div>
            }
          >
             <h3 className="vertical-timeline-element-title font-semibold underline underline-offset-4 italic">
             Explore Orchha
            </h3>
            <ul>
              <li>
                &#8226; Arrive in Orchha and check in to your comfortable hotel..
              </li>
              <li>
                &#8226; Enjoy a fulfilling breakfast (included) and take some rest.
              </li>
              <li>
                &#8226; Visit the famous Orchha Fort, which houses several palaces and temples with stunning architecture and historical significance.
              </li>
              <li>
                &#8226; Explore the Jehangir Mahal, Raj Mahal, and the Chaturbhuj Temple.
              </li>
              <li>
                &#8226; Take a walk along the serene Betwa River and enjoy the scenic beauty.
              </li>
              <li>
                &#8226; As the sun sets, witness the evening Aarti at Ram Raja Temple, a unique experience in itself.
              </li>
              <li>
                &#8226; Savor a delightful dinner (included) and enjoy an overnight stay at the hotel in Orchha.
              </li>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgba(0, 0, 0, 0.3)",
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              color: "white",
            }}
            contentArrowStyle={{
              borderRight: "7px solid var(--static-secondary)",
            }}
            date="Day 3 - Sunday, 27th August"
            iconStyle={{
              background: "var(--secondary)",
              color: "white",
              overflow: "hidden",
            }}
            icon={
              <div className="h-full w-full p-2 bg-[#334155]">
                <img
                  src={darkModeState === "light" ? gftDarkLight : gftDarkLogo}
                  className="h-full w-full object-contain object-center"
                  alt="logo"
                />
              </div>
            }
          >
             <h3 className="vertical-timeline-element-title font-semibold underline underline-offset-4 italic">
             Orchha to Khajuraho
            </h3>
            <ul>
              <li>
                &#8226; Have an early breakfast (included) and check out from the hotel.
              </li>
              <li>
                &#8226; Depart for Khajuraho, a World Heritage Site, approximately 2-3 hours away.
              </li>
              <li>
                &#8226; Arrive in Khajuraho and explore the stunning Khajuraho Temples, renowned for their exquisite sculptures and architecture.
              </li>
              <li>
                &#8226; After dinner (included), depart on an overnight journey to Indore.
              </li>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgba(0, 0, 0, 0.3)",
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              color: "white",
            }}
            contentArrowStyle={{
              borderRight: "7px solid var(--static-secondary)",
            }}
            date="Day 4 - Monday, 28th August"
            iconStyle={{
              background: "var(--secondary)",
              color: "white",
              overflow: "hidden",
            }}
            icon={
              <div className="h-full w-full p-2 bg-[#334155]">
                <img
                  src={darkModeState === "light" ? gftDarkLight : gftDarkLogo}
                  className="h-full w-full object-contain object-center"
                  alt="logo"
                />
              </div>
            }
          >
            <h3 className="vertical-timeline-element-title font-semibold underline underline-offset-4 italic">
            Return to Indore
            </h3>
            <ul>
              <li>
                &#8226; Reach Indore on Monday morning, cherishing the memories of an unforgettable journey.

              </li>
              <li>
                &#8226; Bid farewell to your fellow travelers.
              </li>
              <li>
                &#8226; Return home with incredible experiences and cherished moments.
              </li>
            </ul>
          </VerticalTimelineElement>
        </VerticalTimeline>
        <InclusionsAndExclusions />
        <Terms />
      </div>
    </>
  );
};

export default TimeLineOrcha;
