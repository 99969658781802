const Terms = () => {
  return (
    <div className="w-full mx-auto backdrop-blur-sm border-t-4 p-2 bg-white">
      <h3 className="text-center mx-auto text-xl">
        <span className="font-bold xl:text-4xl">Terms & Conditions</span>{" "}
        {/* <i className="fa-solid fa-circle-question"></i> */}
      </h3>
      <ul className="space-y-4 list-disc px-10 mt-4 xl:w-3/5 mx-auto">
        <li>
          We reserve the right to rearrange to Itinerary to suit Hotel
          availability without changing the total number of days at each
          destination and without compromising on any service.
        </li>

        <li>
          Meals at the accommodations or other services not utilized during the
          tour shall be not liable for any kind of refund from the tour price.
        </li>

        <li>
          Due to unavoidable circumstances, if the company cancels the tour, the
          entire advance/deposit amount will be non-refundable.
        </li>

        <li>
          Management will not take any other liability towards any loss/damage
          of life/property or any other belongings whatsoever of the tourist
          caused by any accident/theft/ robbery or any other unwanted happening
          during the tour.
        </li>

        <li>
          All stays will be on a triple/quad-sharing basis only. Double sharing
          as per availability with extra per-night charges.
        </li>

        <li>All guests must carry a government-issued ID Card.</li>

        <li>
          Confirmation of physical & mental health before starting the
          challenge/trek/tour with us. It is your responsibility to determine
          the ability to participate in any activity and any loss or damage
          caused during so.
        </li>

        <li>
          Due to any avoidable/unavoidable reason, you wish to leave the trip
          early, you will not be entitled to any refund.
        </li>

        <li>
          In case of any injuries/illness on the mountains during the
          trip/activity. Go Foot Travelscas a company or our local staff will
          not be responsible.
        </li>

        <ol>
          <li className="list-disc">Price DO NOT include:</li>
          <li>
            1. Any cost incurred in route to & from the destination for personal
            expenses of the traveler.
          </li>
          <li>
            2. Meals (unless specified), alcoholic beverages, telephone calls.
          </li>
          <li>
            3. Additional personal use of vehicle, any extra sightseeing apart
            from specified in the itinerary.
          </li>
        </ol>
        <li>
          No cancellations will be taken over the Call or WhatsApp message.
          Please forward the confirmation email that you have received at the
          time of booking regarding the cancellation. The Booking Amount is
          non-refundable. The Refundable Amount is the total booking amount
          minus Train Ticket Charges. Whenever IRCTC will refund us we will
          revert you the same minus Agent Charges.
        </li>
      </ul>
    </div>
  );
};

export default Terms;
