import React from "react";
// import gftDarkLogo from "../../images/logos/gftlogodark.png";
// import gftDarkLight from "../../images/logos/gftlogolight.png";
import gftDarkLogo from "../../assets/images/logos/gftlogolight.png";
import gftDarkLight from "../../assets/images/logos/gftlogolight.png";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useSelector } from "react-redux";
import Terms from "../Terms";
import InclusionsAndExclusions from "../../components/InclusionsAndExclusions";

const TimeLine = () => {
  const darkModeState = useSelector((state) => state.darkmode);

  return (
    <>
      <div className="bg-primary w-full bg-[#334155] xl:mt-32 bg-cover bg-center bg-[url(https://images.unsplash.com/photo-1578645546130-a8e4c5becb17?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80)] bg-fixed">
        <VerticalTimeline lineColor="white">
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgba(0, 0, 0, 0.3)",
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              color: "white",
            }}
            contentArrowStyle={{
              borderRight: "7px solid var(--static-secondary)",
            }}
            date="Day 1 - 11th August"
            iconStyle={{
              background: "var(--secondary)",
              color: "white",
              overflow: "hidden",
            }}
            icon={
              <div className="h-full w-full p-2 bg-[#334155]">
                <img
                  src={darkModeState === "light" ? gftDarkLight : gftDarkLogo}
                  className="h-full w-full object-contain object-center"
                  alt="logo"
                />
              </div>
            }
          >
            <h3 className="vertical-timeline-element-title font-semibold">
              Depart from Indore in the night.
            </h3>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgba(0, 0, 0, 0.3)",
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              color: "white",
            }}
            contentArrowStyle={{
              borderRight: "7px solid var(--static-secondary)",
            }}
            date="Day 2 - 12th August"
            iconStyle={{
              background: "var(--secondary)",
              color: "white",
              overflow: "hidden",
            }}
            icon={
              <div className="h-full w-full p-2 bg-[#334155]">
                <img
                  src={darkModeState === "light" ? gftDarkLight : gftDarkLogo}
                  className="h-full w-full object-contain object-center"
                  alt="logo"
                />
              </div>
            }
          >
            <ul>
              <li>&#8226; Arrive in Chittorgarh in the morning.</li>
              <li>&#8226; Check in to your hotel and freshen up.</li>
              <li>
                &#8226; After breakfast, start your sightseeing in Chittorgarh.
              </li>
              <li>
                &#8226; Visit the Chittorgarh Fort, the largest fort in India,
                known for its historical significance.
              </li>
              <li>
                &#8226; Explore other attractions like Vijay Stambh, Kirti
                Stambh, Rana Kumbha Palace, and Padmini Palace.
              </li>
              <li>&#8226; Overnight stay in Chittorgarh.</li>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgba(0, 0, 0, 0.3)",
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              color: "white",
            }}
            contentArrowStyle={{
              borderRight: "7px solid var(--static-secondary)",
            }}
            date="Day 3 - 13th August"
            iconStyle={{
              background: "var(--secondary)",
              color: "white",
              overflow: "hidden",
            }}
            icon={
              <div className="h-full w-full p-2 bg-[#334155]">
                <img
                  src={darkModeState === "light" ? gftDarkLight : gftDarkLogo}
                  className="h-full w-full object-contain object-center"
                  alt="logo"
                />
              </div>
            }
          >
            <ul>
              <li>
                &#8226; After breakfast, check out from the hotel and depart for
                Udaipur.
              </li>
              <li>
                &#8226; The drive from Chittorgarh to Udaipur takes around 3-4
                hours.
              </li>
              <li>&#8226; Check in to your Udaipur hotel and relax.</li>
              <li>
                &#8226; Enjoy a boat ride on Lake Pichola in the evening and
                witness the breathtaking view of the sunset.
              </li>
              <li>&#8226; Dinner at a local restaurant</li>
              <li>&#8226; Overnight stay in Udaipur.</li>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgba(0, 0, 0, 0.3)",
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              color: "white",
            }}
            contentArrowStyle={{
              borderRight: "7px solid var(--static-secondary)",
            }}
            date="Day 4 - 14th August"
            iconStyle={{
              background: "var(--secondary)",
              color: "white",
              overflow: "hidden",
            }}
            icon={
              <div className="h-full w-full p-2 bg-[#334155]">
                <img
                  src={darkModeState === "light" ? gftDarkLight : gftDarkLogo}
                  className="h-full w-full object-contain object-center"
                  alt="logo"
                />
              </div>
            }
          >
            <ul>
              <li>
                &#8226; After breakfast, embark on a full-day Udaipur
                sightseeing tour.
              </li>
              <li>
                &#8226; Visit the City Palace, Jagdish Temple, Saheliyon ki
                Bari, and Fateh Sagar Lake.
              </li>
              <li>&#8226; Enjoy lunch at a local restaurant.</li>
              <li>
                &#8226; In the afternoon, visit the famous Eklingji Temple and
                the nearby Nagda Temples.
              </li>
              <li>
                &#8226; Return to the hotel for dinner (included) and overnight
                stay in Udaipur.
              </li>
              <li>&#8226; Overnight stay in Udaipur.</li>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "rgba(0, 0, 0, 0.3)",
              borderRadius: "16px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              border: "1px solid rgba(0, 0, 0, 0.3)",
              color: "white",
            }}
            contentArrowStyle={{
              borderRight: "7px solid var(--static-secondary)",
            }}
            date="Day 5 - 15th August"
            iconStyle={{
              background: "var(--secondary)",
              color: "white",
              overflow: "hidden",
            }}
            icon={
              <div className="h-full w-full p-2 bg-[#334155]">
                <img
                  src={darkModeState === "light" ? gftDarkLight : gftDarkLogo}
                  className="h-full w-full object-contain object-center"
                  alt="logo"
                />
              </div>
            }
          >
            <ul>
              <li>
                &#8226; After breakfast, check out from the hotel and proceed to
                Mount Abu.
              </li>
              <li>
                &#8226; Mount Abu is a hill station located about 3-4 hours away
                from Udaipur.
              </li>
              <li>
                &#8226; Visit Dilwara Jain Temples, famous for their intricate
                marble carvings.
              </li>
              <li>
                &#8226; Enjoy the serene Nakki Lake and take a boat ride if you
                wish (own expense).
              </li>
              <li>
                &#8226; In the evening, explore the local markets of Mount Abu.
              </li>
              <li>&#8226; Dinner at a local restaurant.</li>
              <li>&#8226; Depart from Mount Abu to Udaipur.</li>
              <li>&#8226; Depart from Udaipur to Indore.</li>
              <li>&#8226; Take back a bag full of memories and new friends.</li>
            </ul>
          </VerticalTimelineElement>
        </VerticalTimeline>
        <InclusionsAndExclusions />
        <Terms />
      </div>
    </>
  );
};

export default TimeLine;
