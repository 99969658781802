import React from "react";
import logo from "../assets/images/logos/gftlogolight.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="sticky h-12 w-full bg-[#64748B] top-0 left-0 flex justify-center items-center z-50">
      <div className="h-11 w-11">
        <img
          alt="website logo"
          src={logo}
          className="h-full w-full object-contain object-center"
        />
      </div>
    </div>
  );
};

export default Navbar;
