import React from "react";

const InclusionsAndExclusions = () => {
  return (
    <div className="w-full xl:h-[50vh] xl:gap-4 bg-white flex flex-col xl:flex-row justify-center items-center">
      <div className="w-full xl:h-full xl:w-[30%] p-10 xl:p-5">
        <ul className="list-decimal flex flex-col justify-evenly items-start h-full w-full space-y-4 xl:space-y-0">
          <h2 className="text-2xl underline underline-offset-4">
            Inclusion{" "}
            <i className="fa-solid fa-circle-check text-green-400"></i>
          </h2>

          <li>
            Fully equipped accommodation in Triple / Quad sharing. Couple rooms
            are available at an extra charge.
          </li>

          <li>
            Breakfasts and Dinners are included in this package which will be a
            Vegetarian Meal.
          </li>

          <li>
            Note: Backpacking Leader will be allotted only if there will be more
            than 10 travelers.
          </li>

          <li>Transportation in Non AC Bus or Tempo Traveller.</li>

          <li>
            All Transportation Permits and Tolls along with driver night
            charges, parking charges, etc are included.
          </li>

          <li>
            24/7 assistance from our trip leaders and online customer support
            where you can resolve any issue related to the trip no matter what
            day or time it is.
          </li>
        </ul>
      </div>
      <div className="w-full xl:h-full xl:w-[30%] p-10 xl:p-5">
        <ul className="list-decimal flex flex-col justify-evenly items-start h-full w-full space-y-4 xl:space-y-0">
          <h2 className="text-2xl underline underline-offset-4">
            Exclusion <i className="fa-solid fa-circle-xmark text-red-400"></i>
          </h2>

          <li>
            Room heater charges are extra and it's not included in any of our
            packages. If you need one you can directly contact Hotel reception.
          </li>

          <li>Anything not mentioned in inclusions.</li>

          <li>
            Monument entry tickets and expenses on any activities which are not
            mentioned in the itinerary.
          </li>

          <li>Any type of insurance including travel, health, and more.</li>

          <li>Expenses of personal nature are not covered.</li>

          <li>Any cost occurred due to any emergency/calamities.</li>

          <li>
            If Tempo Traveller is unable to move due to Snowfall, Landslide,
            etc. then Go Foot Travels will not bear the extra charges of small
            4x4. Either wait till clearing or Pay for the extra service.
          </li>

          <li>Food during travel and lunch is not included.</li>

          <li>We would not be liable for theft or misplaced items.</li>
        </ul>
      </div>
    </div>
  );
};

export default InclusionsAndExclusions;
