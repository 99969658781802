// import Counter from "./pages/Counter";

// libraries
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// pages
import Home from "./pages/home/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Orcha from "./pages/orcha/Orcha";

function App() {
  return (
    <div className="App bg-[#334155]">
      <Router>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path='/orchha-khajuraho' element={<Orcha />} />
        </Routes>
        <Footer />
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
