import React from "react";

const Footer = () => {
  return (
    <div className="text-center text-white py-2">
      For More Info Contact &nbsp; <br className="lg:hidden" />
      <a
        className="underline underline-offset-4"
        href={
          "https://api.whatsapp.com/send/?phone=+919111811192&text=I want to inquire about this trip."
        }
        target="_blank"
      >
        +91 9111811192
      </a>
      &nbsp;&nbsp;or&nbsp;&nbsp;
      <a
        className="underline underline-offset-4"
        href={
          "https://api.whatsapp.com/send/?phone=+918827707762&text=I want to inquire about this trip."
        }
        target="_blank"
      >
        +91 8827707762
      </a>
    </div>
  );
};

export default Footer;
