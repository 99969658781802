import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "../../components/Carousel";
import { toast } from "react-toastify";
import axios from "axios";

// Orcha-Khajuraho

import image1 from "../../assets/images/carousel-images/Orcha-Khajuraho/Khajuraho1.jpeg";
import image2 from "../../assets/images/carousel-images/Orcha-Khajuraho/Khajuraho2.jpeg";
import image3 from "../../assets/images/carousel-images/Orcha-Khajuraho/Khajuraho3.jpeg";
import image4 from "../../assets/images/carousel-images/Orcha-Khajuraho/Khajuraho4.jpeg";
import image5 from "../../assets/images/carousel-images/Orcha-Khajuraho/Orcha1.jpeg";
import image6 from "../../assets/images/carousel-images/Orcha-Khajuraho/Orcha2.jpeg";
import image7 from "../../assets/images/carousel-images/Orcha-Khajuraho/Orcha3.jpeg";
import image8 from "../../assets/images/carousel-images/Orcha-Khajuraho/Orcha4.jpeg";
import TimeLineOrcha from "./TimeLineOrcha";

// social icons

import facebook from "../../assets/logos/facebook_4401395.png";
import instagram from "../../assets/logos/instagram_2626270.png";
import twitter from "../../assets/logos/twitter_4401446.png";
import whatsapp from "../../assets/logos/whatsapp_2626279.png";

const toastOptions = {
  position: "top-center",
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  // progress: undefined,
  theme: "colored",
};

const ContactForm = () => {
  const initialData = {
    from: "Udaipur - Mount Abu - Chittorgarh",
    name: "",
    email: "",
    phoneNumber: "",
    amountOfPeople: 1,
  };
  const [data, setData] = useState(initialData);
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      from: "Orchha & Khajuraho",
    });
  };
  const handleSubmission = async (e) => {
    e.preventDefault();
    if (data.name === "") {
      toast.error("please enter name", toastOptions);
      return;
    } else if (data.email === "") {
      toast.error("please enter email", toastOptions);
      return;
    } else if (data.phoneNumber === "") {
      toast.error("please enter phoneNumber", toastOptions);
      return;
    } else if (data.amountOfPeople === 0 || data.amountOfPeople === "") {
      toast.error("please enter the amount of People", toastOptions);
      return;
    }
    const res = await axios.post(
      "https://getform.io/f/bf14b58b-a19e-49d1-aa6b-6cbb5adfefff",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (res.status === 200) {
      toast.success("we will contact you soon!", toastOptions);
      setData(initialData);
    } else {
      toast.error("there was some error please try again", toastOptions);
    }
  };
  return (
    <div className="bg-[#0007] xl:shadow-lg xl:h-[25vh] w-full xl:w-4/5 backdrop-blur-md xl:absolute xl:top-full xl:left-1/2 xl:-translate-x-1/2 z-40 xl:-translate-y-1/2 px-4 py-4 xl:py-0 gap-4 xl:gap-0 flex flex-col justify-evenly xl:rounded-md">
      <h2 className="capitalize text-xl xl:text-2xl text-white">
        get in touch
      </h2>
      <div className="h-[1px] w-full bg-white"></div>
      <form
        className="flex justify-between items-center flex-col xl:flex-row gap-2"
        onSubmit={handleSubmission}
      >
        <div className="flex flex-col justify-center items-start gap-4 w-full">
          <h3 className="hidden md:block text-lg xl:text-xl text-gray-200">
            Name...
          </h3>
          <input
            className="p-2 rounded-md text-lg xl:text-xl border border-black w-full"
            placeholder="Name..."
            type="text"
            onChange={handleChange}
            name="name"
            value={data.name}
          />
        </div>
        <div className="flex flex-col justify-center items-start gap-4 w-full">
          <h3 className="hidden md:block text-lg xl:text-xl text-gray-200">
            Email...
          </h3>
          <input
            className="p-2 rounded-md text-lg xl:text-xl border border-black w-full"
            placeholder="Email..."
            type="email"
            onChange={handleChange}
            name="email"
            value={data.email}
          />
        </div>
        <div className="flex flex-col justify-center items-start gap-4 w-full">
          <h3 className="hidden md:block text-lg xl:text-xl text-gray-200">
            Contact Number...
          </h3>
          <input
            className="p-2 rounded-md text-lg xl:text-xl border border-black w-full"
            placeholder="Contact Number..."
            type="text"
            onChange={handleChange}
            name="phoneNumber"
            value={data.phoneNumber}
          />
        </div>
        <div className="flex flex-col justify-center items-start gap-4 w-full">
          <h3 className="hidden md:block text-lg xl:text-xl text-gray-200">
            Number of People...
          </h3>
          <input
            className="p-2 rounded-md text-lg xl:text-xl border border-black w-full"
            placeholder="Number of People..."
            type="number"
            onChange={handleChange}
            name="amountOfPeople"
            value={data.amountOfPeople}
          />
        </div>
        <div className="h-4/5 flex flex-col justify-center items-start gap-4">
          <h3 className="text-xl text-transparent hidden md:block">Name...</h3>
          <button className="py-2 px-4 bg-white capitalize border border-black rounded-md">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

const Orcha = () => {
  useEffect(() => {
    document.title = "Orchha & Khajuraho";
  }, []);

  // const isIOS =
  //   /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const arrString = ["Indore", "Orchha", "Khajuraho"].join(" ➠ ");

  const SlideShow = [
    <div className="h-[70vh] xl:h-screen border w-full bg-[#334155]">
      <img
        src={image1}
        className="h-full w-full object-cover object-center"
        alt="location"
      />
    </div>,
    <div className="h-[70vh] xl:h-screen border w-full bg-[#334155]">
      <img
        src={image5}
        className="h-full w-full object-cover object-center"
        alt="location"
      />
    </div>,
    <div className="h-[70vh] xl:h-screen border w-full bg-[#334155]">
      <img
        src={image2}
        className="h-full w-full object-cover object-center"
        alt="location"
      />
    </div>,
    <div className="h-[70vh] xl:h-screen border w-full bg-[#334155]">
      <img
        src={image6}
        className="h-full w-full object-cover object-center"
        alt="location"
      />
    </div>,
    <div className="h-[70vh] xl:h-screen border w-full bg-[#334155]">
      <img
        src={image3}
        className="h-full w-full object-cover object-center"
        alt="location"
      />
    </div>,
    <div className="h-[70vh] xl:h-screen border w-full bg-[#334155]">
      <img
        src={image7}
        className="h-full w-full object-cover object-center"
        alt="location"
      />
    </div>,
    <div className="h-[70vh] xl:h-screen border w-full bg-[#334155]">
      <img
        src={image4}
        className="h-full w-full object-cover object-center"
        alt="location"
      />
    </div>,

    <div className="h-[70vh] xl:h-screen border w-full bg-[#334155]">
      <img
        src={image8}
        className="h-full w-full object-cover object-center"
        alt="location"
      />
    </div>,
  ];

  return (
    <div className="flex flex-col justify-center items-center w-full relative">
      {/* hero section */}
      <div className="flex flex-col justify-center items-center h-[70vh] xl:h-screen w-full relative">
        <Carousel data={SlideShow} setmode="fadeout" />
        <div className="flex flex-col justify-center items-center h-full w-full bg-[#0009] absolute top-0 left-0 gap-8">
          <p className="text-white text-md xl:text-xl">{arrString}</p>
          <h1 className="mt-10 xl:mt-0 text-2xl xl:text-6xl capitalize text-white text-center w-4/5 leading-relaxed heading-font">
            Unwind on a Weekend Journey and Discover Heritage!
          </h1>
          <Link
            target="_blank"
            to={
              "https://api.whatsapp.com/send/?phone=+918827707762&text=I want to inquire about the Orchha and Khajuraho trip."
            }
          >
            <button className="py-2 px-4 bg-white capitalize">
              Have any queries?
            </button>
          </Link>
        </div>
        <div className="hidden xl:block w-full">
          <ContactForm />
        </div>
      </div>
      <div className="block xl:hidden w-full">
        <ContactForm />
      </div>

      {/* content */}

      <TimeLineOrcha />

      {/* socials */}
      <div className="flex lg:flex-col justify-center items-center lg:fixed lg:top-1/2 lg:-translate-y-1/2 lg:left-4 lg:space-y-4 text-2xl lg:backdrop-blur-sm p-2 rounded-md mx-auto lg:mx-0 space-x-4 lg:space-x-0">
        <Link to={"https://www.facebook.com/gofoottravels"} target="_blank">
          <div className="h-10 w-10">
            <img
              src={facebook}
              className="h-full w-full object-contain object-center drop-shadow-lg"
            />
          </div>
        </Link>
        <Link to={"https://www.instagram.com/gofoottravels/"} target="_blank">
          <div className="h-10 w-10">
            <img
              src={instagram}
              className="h-full w-full object-contain object-center drop-shadow-lg"
            />
          </div>
        </Link>
        <Link to={"https://twitter.com/gofoottravels"} target="_blank">
          <div className="h-10 w-10">
            <img
              src={twitter}
              className="h-full w-full object-contain object-center drop-shadow-lg"
            />
          </div>
        </Link>
        <Link
          to={
            "https://api.whatsapp.com/send/?phone=+918827707762&text=I want to inquire about this trip."
          }
          target="_blank"
        >
          <div className="h-10 w-10">
            <img
              src={whatsapp}
              className="h-full w-full object-contain object-center drop-shadow-lg"
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Orcha;
